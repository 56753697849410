
/* text */

.text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  letter-spacing: 0.005em;
}


.lh22 {
  line-height: 1.375em;
}

.lh24 {
  line-height: 1.5em;
}

.lh28 {
  line-height: 1.75em;
}

.lh30 {
  line-height: 1.875em;
}

.lh32 {
  line-height: 2em;
}

.lh44 {
  line-height: 2.75em;
}


.fs16 {
  font-weight: 400;
  font-size: 1em;
}

.fs18 {
  font-weight: 400;
  font-size: 1.125em;
}

.fs20 {
  font-weight: 400;
  font-size: 1.25em;
}

.fs22 {
  font-weight: 400;
  font-size: 1.375em;
}

.fs24 {
  font-weight: 400;
  font-size: 1.5em;
}

.fs26 {
  font-weight: 600;
  font-size: 1.625em;
}

.fs30 {
  font-weight: 600;
  font-size: 1.875em;
}

.fs40 {
  font-weight: 600;
  font-size: 2.5em;
}

.fw400 {
  font-weight: 400;
}