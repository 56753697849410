#mailpage {
  position: fixed;
  background: #394d66;
  height: 100%;
  width: 100%;
  padding: 30px 30px 30px 45px;
  overflow-y: auto;
}
#rcorners0 {
  border-radius: 25px;
  background: #E3F3E5;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-flow: column;
  
}
#rcorners1 {
  border-radius: 25px;
  background: #97e1e0;
  padding: 0px;
  width: 100%;
  height: 150px;
}
#rcorners4 {
  border-radius: 25px;
  background: #f2f2f2;
  padding: 0px;
  width: 100%;
  height: auto;
}
#rcorners3 {
  border-radius: 25px;
  background: #f2f2f2;
  padding: 0px;
  width: 100%;
  height: 100%;
}
#rcorners2 {
  border-radius: 25px;
  background-image: linear-gradient(#006DBA,#61AFE2);
  padding: 0px;
  width: 100%;
  height: 100px;
}
#rcornerswork {
  border-radius: 25px;
  background: #ffffff;

  padding: 20px;
  margin: 30px;  
 
}
#rcornerswork1 {
  border-radius: 25px;
  background: #ffffff;

  padding: 20px;
  margin: 10px;  
  margin-left: 60px;
  margin-right: 60px;
}

#rmenubarwork {
  margin-top: 10px;
  margin-left: 60px;
  margin-right: 60px;
}

.imagercorners {
  border-radius: 25px 25px 0 0;
  background: #000000;
  padding: 0px;
  width: 100%;  
  text-align: center;
}

.imagedetailcorners {
  border-radius: 0 0 25px 25px;
  background: #ffffff;
  padding: 0px;
  width: 100%;  
  text-align: center;
  height: 100px;
  

}
.imagesdataheader {
  color: #4e4e4e;
  padding-top: 10px;
  padding-bottom: 10px;
}
.imagesdata {
  color: #b3b3b3;
}
.reporttick {
  vertical-align:middle;
  padding-right: 10px;
  max-height:18px;
}
.eyetext {
  position: relative;
  top: 20px;
  left: 30px;
  color: #ffffff;
  z-index: 1;
  text-align: left;
  height: 0px;
}
.diagimage {
  width: 400px;
  height: 400px;
}
.aeyetestimagecenter {
	position: absolute;
	text-align: center;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;		
}
.gallery .thumbnail{
	margin:2px;
}
.iiqborder {
  border-style: solid;
  border-width: 6px;
  border-color: rgb(255, 0, 0);
}
.dupborder {
  border-style: solid;
  border-width: 15px;
  border-color: rgb(255, 251, 0);
}

div.filewrapper {
	position: relative;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
}

div.filewrapper input { 
	opacity:0;
}

div.filewrapper .text {
    position: absolute;
    top: 0;
    left: 35%; 
    text-decoration: none;
    color: #4a4a4a;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    width: 100%;
    height: 50px;
}
.imgAutoSize {
  max-width:100%;
  max-height:100%;
}
.btn-shine {
  background: linear-gradient(to right, red 0, white 10%, red 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 4s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: medium;
  text-decoration: none;
  white-space: nowrap;
}
@keyframes shine {
  
  0%
    {background-position: -170px;}

}




/* user manual */

h1.code-line{
  font-family: auto;
  font-weight: bold;
}

h2.code-line{
  font-family: auto;
  font-weight: bold;
}

p.has-line-data {
  font-size: 1.25em;
}

li.has-line-data{
  font-size: 1.25em;
}