
/* backdrop */

.backdrop.zoom {
  opacity: 0.9;
}



/* exam screen */

.examid-input {
  background: #1C1C1C;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  height: 2.2em;
  width: 35%;
  padding: 2%;
}

.exam-reset-button {
  color: white;
}

.exam-reset-button:hover {
  color: #d2dadf;
}



/* eye input */

.bg-image {
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding: 3%;
  background-position: center;
  position: relative;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
}

.spinner-location {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  z-index: 1060;
}

.iiq-upload-image {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  text-decoration-line: underline;
  color: white
}

.zoom-icon-location {
  position: absolute; 
  top: 91%; 
  left: 91%; 
  transform: translate(-50%, -50%)
}

.zoom-window-location {
  background-repeat: no-repeat;
  background-size: contain;
  background-color: black;
  background-position: center;

  z-index: 99999;
  padding: 1%;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  height: 95vh;
  width: 95vh;
  display: flex;
  align-items: center;
  justify-content: center
}

.close-zoom-icon-location {
  position: absolute; 
  bottom: 91%; 
  left: 91%; 
  transform: translate(50%, -50%)
}



/* general */

.text-align-webkit-right {
  text-align: -webkit-right;
}

.text-align-webkit-center {
  text-align: -webkit-center;
}

.exam-container {
  padding-right: 7.15%;
  padding-left: 5.8%;
}
