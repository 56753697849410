
.logo-img {
  height: 5%;
  position: absolute;
}


/* backdrop */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
}


/* scrollbar */

.scroll::-webkit-scrollbar {
  width: 7px;
}

.scroll::-webkit-scrollbar-track {
  background: #1A1919;
  border-left: 1px solid #4F565B;
}

.scroll::-webkit-scrollbar-thumb {
  background-color:#4F565B;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color:#2e2e2e;
}

.diseases-scroll {
  overflow-y: scroll;
  height: 94.7%;
  direction:ltr;
  margin-right: 15px;
}

.diseases-scroll::-webkit-scrollbar {
  width: 7px;
}

.exam-scroll {
  overflow-y: auto;
  height: -webkit-fill-available;
}


/* button primary */

.button.btn-primary {
  background-color: #5CA5FF;
  border-color: #5CA5FF;
}

.button.btn-primary:focus{
  box-shadow: none;
  background-color: #5CA5FF;
  border-color: #5CA5FF;
}

.button.btn-primary:hover{
  box-shadow: none;
  background-color: #4B99F9;
  border-color: #4B99F9;
}

.button.btn-primary:active{
  box-shadow: none;
  background-color: #72B0FD;
  border-color: #72B0FD;
}

.button.btn-primary:disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #5CA5FF;
  border-color: transparent;
}



/* button dark */

.button.btn-dark {
  box-shadow: none;
  background-color: #000000;
  border: 1px solid #FFFFFF;
}

.button.btn-dark:focus{
  box-shadow: none;
  background-color: #000000;
  border: 1px solid #FFFFFF;
}

.button.btn-dark:hover{
  box-shadow: none;
  background-color: #222222;
  border: 1px solid #FFFFFF;
}

.button.btn-dark:active{
  box-shadow: none;
  background-color: #222222;
  border: 1px solid #FFFFFF;
}



/* borders */

.left-border {
  border-left: 1px solid #4F565B;
}

.right-border {
  border-right: 1px solid #4F565B;
}

.bottom-border {
  border-bottom: 1px solid #4F565B;
}



/* login */

.login-bg {
  background-image: url(../../public/bg.png);
  height: inherit;
  background-size: cover;
  background-position: center;
}

.login-logo {
  width: 6.0625em;
  height: 6.0625em;
}

/* login-fields */

.login-fields{
  float: left;
  margin-left: 28vh;
  margin-top: 7vw;
}

.pwd-container {
  
  width: 25em;
}

.login-input {
  color: #C4C4C4;
  background: rgba(196, 196, 196, 0.14);
  border-bottom: 1px solid #C4C4C4 !important;
  border: none;
  width: 100%;
  border-radius: 3px 3px 0px 0px;
}

.login-input:focus{
  outline: none;
}



/* exam card */

.exam-card {
  padding-left: 15%;
}

.exam-card:hover {
  background-color: #383838;
}

.text-nowrap:hover {
  text-decoration: none;
}

.exam-card-displayed {
  padding-left: 8%;
  background-color: #5A98E3;
  border-left: 1.7rem solid white;
}

.exam-card-displayed .exam-card-datetext {
  color: #FFFFFF;
}

.exam-card-spinner-location {
  position: absolute;
  right: 0%;
  transform: translate(0%, -145%);
}

.exam-card-new-location {
  position: absolute;
  right: 3%;
  transform: translate(0%, -350%);
}



/* diseas card */

.diseas-card {
  padding-left: 10%;
}

.diseas-card-healty {
  border-left: 0.6vw solid #6DCE71;
  padding-left: 7%;
}

.diseas-card-not-analyzed {
  border-left: 0.6vw solid #B7BCC0;
  padding-left: 7%;
}

.diseas-card-sick {
  border-left: 0.6vw solid #FE4848;
  padding-left: 7%;
}

.diseas-card-medium {
  border-left: 0.6vw solid orange;
  padding-left: 7%;
}

.diseas-card-sick> .text.gray_3 {
  color:#FFFFFF;
}

.diseas-card-healty> .text.gray_3 {
  color:#FFFFFF;
}

.diseas-card-inconclusive {
  border-left: 0.6vw solid #669ec8;
  padding-left: 7%;
}


/* search button */

#active_arcived_button {
  background-color: #000000;
  border-color: white;
  border-radius: 4px 0px 0px 4px;
  color: white;
  box-shadow: none;
  width: 100%;
}

#active_arcived_button_menu.dropdown-menu.show {
  display: block;
  background-color: #000000;
  border: 1px solid #ffffff;
  min-width: auto;
  max-width: 100%;
}

#active_arcived_button_menu.dropdown-menu> a:hover{
  background-color:#383838;
}

input[type="search"]#mainlist_search::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  background: url("../../public/X.png") no-repeat 50% 50%;  
}

/* user button */

#navbar_userbutton_menu.dropdown-menu.show {
  display: block;
  background-color: #000;
  border: 1px solid #fff;
  width: 14.375em;
  inset: 1vh 0.75vw auto auto!important;
}

#navbar_userbutton_menu.dropdown-menu> a:hover{
  background-color: #000000;
}

#navbar_userbutton_menu.dropdown-menu> a> .gray_3:hover{
  background-color: #000000;
  color: white;
}

#navbar_userbutton_toggle {
  background-color: #000000;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px 0px 0px 4px;
  color: white;
  box-shadow: none;
  border: 0px none;
  padding-right: 2vw;
}

 #navbar_userbutton_menu> .dropdown-item {
  color:#ffffff;
}


/* .iiq-popup */

.modal-dialog.iiq-popup{
  width: 25.9375em;
}

.iiq-popup> .modal-content {
  background-color: #FFFFFF;
  color:#191919;
  border-radius: 0.2rem;
  align-items: center;
}

.iiq-popup> .modal-content> .modal-header {
  border: 0px none;
  align-items: center;
  flex-direction: column;
  padding: 4% 3% 1% 3%;
}

.iiq-popup> .modal-content> .modal-body {
    font-size: 1.125em;
    word-break: break-word;
    text-align: center;
    padding: 1rem 2rem 1rem 2rem;
}

.iiq-popup> .modal-content> .modal-footer {
  width: inherit;
  place-content: space-around;
}

.iiq-popup> .modal-content> .row div:hover{
  background-color:#e9ecef;
}

.toast-popup> .modal-content {
  align-items: center;
  color: #ffffffca;
  background-color: #323232ca;
  border: none;
  border-radius: 4px;  
}




/* tooltip */

.my-tooltip> .tooltip-inner {
  background-color: #E4E4E4 !important;
  color: #191919 !important;
  border-radius: 6px !important;
}

.my-tooltip.tooltip.show {
  opacity: 1;
}

.my-tooltip> .arrow::before {
  border-bottom-color: #E4E4E4 !important;
}



/* padding */

.pb-1 {
  padding-bottom: 0.25em !important;
}

.pb-2 {
  padding-bottom: 0.5em !important;
}

.pb-3 {
  padding-bottom: 1em !important;
}

.pb-4 {
  padding-bottom: 1.5em !important;
}

.pb-5 {
  padding-bottom: 3em !important;
}



/* screen */

@media screen and (max-width: 1920px) {
  body {font-size: 16px !important; }
  html {font-size: 16px !important; }
} 

@media screen and (max-width: 1800px) {
  body {font-size: 15px !important; }
  html {font-size: 15px !important; }
} 

@media screen and (max-width: 1680px) {
  body {font-size: 14px !important; }
  html {font-size: 14px !important; }
} 

@media screen and (max-width: 1560px) {
  body {font-size: 13px !important; }
  html {font-size: 13px !important; }
} 

@media screen and (max-width: 1440px) {
  body {font-size: 12px !important;}
  html {font-size: 12px !important; }
}

@media screen and (max-width: 1232px) {
  body {font-size: 11px !important;}
  html {font-size: 11px !important; }
}

@media screen and (max-width: 1024px) {
  body {font-size: 10px !important;}
  html {font-size: 10px !important; }
}

@media screen and (max-width: 912px) {
  body {font-size: 9px !important;}
  html {font-size: 9px !important; }
}

@media screen and (max-width: 800px) {
  body {font-size: 8px !important;}
  html {font-size: 8px !important; }
}