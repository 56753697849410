
/* colors */

.bg-black {
  background-color: black;
}

.green {
  color:#6DCE71;
}

.red {
  color:#FE4848;
}

.black {
  color:#191919;
}

.white {
  color:white !important;
}

.gray_1 {
  color:#4F565B;
}

.gray_2 {
  color:#AFAFAF;
}

.gray_3 {
  color:#B7BCC0;
}

.blue_1 {
  color:#5CA5FF;
}

.blue_2 {
  color: #43A0F4;
}

.orange {
  color:orange;
}

.yellow {
  color:yellow;
}

.lightblue {
  color: #669ec8;
}