body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pwd-container {
  position: relative;
  width: 295px;
}

.pwd-container input {
  padding: 5px 30px 5px 10px;
  font-size: 20px;
}

.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 8px;
}
